<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Kualifikasi Welder'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="welder_id_label"
                  label="Welder :"
                  label-for="welder_id"
                >
                  <b-form-select
                    size="sm"
                    id="welder"
                    v-model="form.welder_id"
                    :options="welder"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="proses_pengelasan_label"
                  label="Proses Pengelasan :"
                  label-for="proses_pengelasan"
                >
                  <b-form-input
                    id="proses_pengelasan"
                    v-model="form.proses_pengelasan"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                  <!-- <vue-editor
                    id="proses_pengelasan"
                    v-model="form.proses_pengelasan"
                  ></vue-editor> -->
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="no_p_label"
                  label="P No. :"
                  label-for="no_p"
                >
                  <b-form-input
                    id="no_p"
                    v-model="form.no_p"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="no_f_label"
                  label="F No. :"
                  label-for="no_f"
                >
                  <b-form-input
                    id="no_f"
                    v-model="form.no_f"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="diameter_label"
                  label="Diameter :"
                >
                  <b-row>
                    <b-col>
                      <b-form-input
                        id="diameter_min"
                        v-model="form.diameter_min"
                        type="text"
                        placeholder="Min..."
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <b-form-input
                        id="diameter_max"
                        v-model="form.diameter_max"
                        type="text"
                        placeholder="Max..."
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="tebal_label"
                  label="Tebal :"
                >
                  <b-row>
                    <b-col>
                      <b-form-input
                        id="tebal_min"
                        v-model="form.tebal_min"
                        type="text"
                        placeholder="Min..."
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <b-form-input
                        id="tebal_max"
                        v-model="form.tebal_max"
                        type="text"
                        placeholder="Max..."
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="polaritas_label"
                  label="Polaritas :"
                  label-for="polaritas"
                >
                  <b-form-input
                    id="polaritas"
                    v-model="form.polaritas"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="posisi_label"
                  label="Posisi :"
                  label-for="posisi"
                >
                  <b-form-input
                    id="posisi"
                    v-model="form.posisi"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="6"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="tanggal_kualifikasi_label"
                  label="Tanggal Kualifikasi :"
                  label-for="tanggal_kualifikasi"
                >
                  <b-form-input
                    id="tanggal_kualifikasi"
                    v-model="form.tanggal_kualifikasi"
                    type="date"
                    placeholder="Enter Date..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="6"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nomor_kualifikasi_label"
                  label="Nomor Kualifikasi :"
                  label-for="nomor_kualifikasi"
                >
                  <b-form-input
                    id="nomor_kualifikasi"
                    v-model="form.nomor_kualifikasi"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="6"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="attachment_label"
                  label="Attachment :"
                  label-for="attachment"
                >
                  <b-form-file
                    id="attachment"
                    v-model="form.attachment"
                    placeholder="Choose a photo or drop it here..."
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                </b-form-group>

                <b-alert class="pl-2" variant="danger" :fade="true" v-model="showDismissibleAlert">
                  <span v-for="(val, index) in errors" :key="index">
                    <h6>{{ index.replace(/_/g, ' ').toUpperCase() + ':' }}</h6>
                    <ul>
                      <li v-for="(arr, i) in val" :key="i">
                        {{ arr }}
                      </li>
                    </ul>
                    <br />
                  </span>
                </b-alert>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/kualifikasi_las/kualifikasi_welder')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import $ from 'jquery'

export default {
  mixins: [validationMixin],
  name: 'addhistory',
  data() {
    return {
      form: {
        welder_id: null,
        proses_pengelasan: null,
        no_p: null,
        no_f: null,
        diameter_min: null,
        diameter_max: null,
        tebal_min: null,
        tebal_max: null,
        polaritas: null,
        posisi: null,
        tanggal_kualifikasi: null,
        nomor_kualifikasi: null,
        attachment: null,
      },
      welder: [],

      errors: [],
      showDismissibleAlert: false,
      loading: false,
    }
  },
  components: {
    KTCodePreview,
    // VueEditor,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Kualifikasi Welder',
        route: '/kualifikasi_las/kualifikasi_welder',
      },
      { title: 'Create Kualifikasi Welder' },
    ])

    var self = this

    ApiService.get('/kualifikasi_las/welder/all?sortBy=nama_welder&sortDesc=ASC')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var welder = data.data
          for (let i = 0; i < welder.length; i++) {
            self.welder.push({
              value: welder[i].id_welder,
              text: welder[i].nama_welder,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    onSubmit() {
      // event.preventDefault()
      var self = this
      self.loading = true
      const welder_id = self.form.welder_id
      const proses_pengelasan = self.form.proses_pengelasan
      const no_p = self.form.no_p
      const no_f = self.form.no_f
      const diameter_min = self.form.diameter_min
      const diameter_max = self.form.diameter_max
      const tebal_min = self.form.tebal_min
      const tebal_max = self.form.tebal_max
      const polaritas = self.form.polaritas
      const posisi = self.form.posisi
      const tanggal_kualifikasi = self.form.tanggal_kualifikasi
      const nomor_kualifikasi = self.form.nomor_kualifikasi
      const attachment = self.form.attachment

      const dataku = new FormData()
      if (welder_id) dataku.append('welder_id', welder_id)
      if (proses_pengelasan) dataku.append('proses_pengelasan', proses_pengelasan)
      if (no_p) dataku.append('no_p', no_p)
      if (no_f) dataku.append('no_f', no_f)
      if (diameter_min) dataku.append('diameter_min', diameter_min)
      if (diameter_max) dataku.append('diameter_max', diameter_max)
      if (tebal_min) dataku.append('tebal_min', tebal_min)
      if (tebal_max) dataku.append('tebal_max', tebal_max)
      if (polaritas) dataku.append('polaritas', polaritas)
      if (posisi) dataku.append('posisi', posisi)
      if (tanggal_kualifikasi) dataku.append('tanggal_kualifikasi', tanggal_kualifikasi)
      if (nomor_kualifikasi) dataku.append('nomor_kualifikasi', nomor_kualifikasi)

      if (attachment) {
        dataku.append('attachment', attachment)
      }

      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + '/kualifikasi_las/kualifikasi_welder/store',
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/kualifikasi_las/kualifikasi_welder')
          } else if (response.status == 'validation_error') {
            self.errors = response.errors
            self.showDismissibleAlert = true
            self.loading = false
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
